'use client';

import { useAppDispatch } from '@/lib/hooks';
import { setCurrentGroupId } from '@/lib/users/userSlice';
import { useParams } from 'next/navigation';
import React, { useEffect } from 'react';

const template = ({ children }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const groupId = params.groupId;
  useEffect(() => {
    if (groupId) {
      localStorage.setItem('groupId', groupId);
      localStorage.setItem('lastGroupId', groupId);
      dispatch(setCurrentGroupId(groupId));
    }
  }, [groupId]);
  return (
    <div className="h-full w-full" key={groupId}>
      {children}
    </div>
  );
};

export default template;
